import { Theme } from '@mui/material';

export const styles = (theme: Theme) =>
    ({
        providerRoot: {
            // We should wrap children of the provider component
            // and set relative position to the wrapper in order to use
            // the notifications overlay with absolute positioning
            position: 'relative',
        },

        notificationsOverlay: {
            position: 'fixed',
            bottom: 0,
            maxHeight: '100%',
            left: 0,
            zIndex: theme.zIndex.snackbar,
            overflow: 'hidden',
            pointerEvents: 'none',

            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(6),
            '&$overlayAnchorOriginTopRight': {
                paddingRight: theme.spacing(2),
            },

            justifyContent: 'flex-start',
            flexWrap: 'nowrap',
        },
        overlayAnchorOriginTopRight: {},

        snackbarNotificationWrapper: {
            opacity: 1,
            pointerEvents: 'all',
            float: 'right',
            paddingTop: 0,
            paddingBottom: theme.spacing(1),

            '&$mostRecentTopNotificationWrapper': {
                '&:first-of-type': {
                    paddingTop: theme.spacing(6),
                },
                '&:last-child': {
                    paddingBottom: 0,
                },
            },
            '&$mostRecentBottomNotificationWrapper': {
                '&:last-child': {
                    paddingTop: theme.spacing(6),
                },
                '&:first-of-type': {
                    paddingBottom: 0,
                },
            },
            '&$autoRemovingNotificationWrapper': {
                animationName: '$notificationFadingOut',
            },
        },
        '@keyframes notificationFadingOut': {
            '0%': {
                opacity: 1,
            },
            '50%': {
                opacity: 1,
            },
            '100%': {
                opacity: 0,
            },
        },
        mostRecentTopNotificationWrapper: {},
        mostRecentBottomNotificationWrapper: {},
        autoRemovingNotificationWrapper: {},
        clickableNotification: {
            cursor: 'pointer !important',
        },

        notification: {
            minWidth: theme.spacing(30),
            maxWidth: theme.spacing(75),
        },

        snackbarNotificationRoot: {
            position: 'static',
            zIndex: 'unset',
            top: 'unset',
            bottom: 'unset',
            left: 'unset',
            right: 'unset',
            margin: 0,
            whiteSpace: 'normal',
        },
    }) as const;
